import React from "react"
import { Link } from "gatsby"

function Footer() {
  return (
    <footer
      style={{
        borderTop: "solid 1px #ffffff75",
        paddingTop: "5px",
        margin: "5px 0",
      }}
    >
      © {new Date().getFullYear()},{" "}
      <Link to="/">
        <span style={{ color: "white" }}>Vishwanath</span>
        <strong style={{ color: "#ff6161" }}>Prem</strong>
        <span style={{ color: "white" }}>.com</span>
      </Link>
    </footer>
  )
}

export default Footer
