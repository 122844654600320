import React from "react"

export const BandCamp = props => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fab"
    data-icon="bandcamp"
    class="svg-inline--fa fa-bandcamp fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 512"
  >
    <path
      fill="currentColor"
      d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm48.2 326.1h-181L199.9 178h181l-84.7 156.1z"
    ></path>
  </svg>
)
