import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Modal from "@material-ui/core/Modal"
import List from "@material-ui/core/List"
import ListItemText from "@material-ui/core/ListItemText"
import ListItem from "@material-ui/core/ListItem"
import Paper from "@material-ui/core/Paper"
import Fab from "@material-ui/core/Fab"
import IconButton from "@material-ui/core/IconButton"

import MenuRoundedIcon from "@material-ui/icons/MenuRounded"
import SocialIcons from "../components/frontpage/socialIcons"
import HomeRoundedIcon from "@material-ui/icons/HomeRounded"

function Header({ siteTitle, isHome }) {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const ListItemLink = props => {
    return (
      <ListItem
        className="menu-link"
        button
        component="a"
        {...props}
        onClick={handleClose}
      />
    )
  }

  const GatsbyLink = props => {
    return (
      <ListItem
        className="menu-link"
        button
        component={Link}
        {...props}
        onClick={handleClose}
      />
    )
  }

  return (
    <Fragment>
      <header
        style={{
          display: "flex",
          alignItems: "center",
          height: "55px",
        }}
      >
        <Fab
          color="primary"
          onClick={handleOpen}
          aria-label="menu"
          size="small"
        >
          <MenuRoundedIcon />
        </Fab>
        {isHome ? (
          undefined
        ) : (
          <Fragment>
            <Link to="/">
              <IconButton>
                <HomeRoundedIcon />
              </IconButton>{" "}
            </Link>
            <SocialIcons />
          </Fragment>
        )}
      </header>
      <Modal
        style={{ outline: 0 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper
          elevation={3}
          className="side-bar"
          style={{
            border: "solid 1px white",
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "250px",
          }}
        >
          <List component="nav" aria-label="main mailbox folders">
            <GatsbyLink to="/blog/">
              <ListItemText primary="गीत/कविता" />
            </GatsbyLink>
            <GatsbyLink to="/videos/">
              <ListItemText primary="भिडियो" />
            </GatsbyLink>
            <ListItemLink href="https://forms.gle/qSvkKG4bDBTE9MvXA">
              <ListItemText primary="सम्पर्क फारम" />
            </ListItemLink>
          </List>
        </Paper>
      </Modal>
    </Fragment>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
