import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import { ThemeProvider } from "@material-ui/core/styles"
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"

import Header from "./header"
import Footer from "./footer"

import "./layout.css"

const Layout = ({ children, isHome = false }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let theme = createMuiTheme({
    palette: {
      type: "dark",
      primary: { main: "#f44336" },
      secondary: { main: "#673ab7" },
    },
  })
  theme = responsiveFontSizes(theme)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="md">
        <Box
          display="flex"
          flexDirection="column"
          style={{ minHeight: "100vh" }}
        >
          <Header siteTitle={data.site.siteMetadata.title} isHome={isHome} />
          <main style={{ flex: 1 }}>{children}</main>
          {isHome ? undefined : <Footer />}
        </Box>
      </Container>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
