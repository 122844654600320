import React, { Fragment } from "react"

import IconButton from "@material-ui/core/IconButton"
import SvgIcon from "@material-ui/core/SvgIcon"

import YouTubeIcon from "@material-ui/icons/YouTube"
import FacebookIcon from "@material-ui/icons/Facebook"
import { BandCamp } from "../../assets/icons/icons"

function SocialIcons() {
  return (
    <Fragment>
      <IconButton href="https://www.youtube.com/channel/UCBKqWATO9SXHmU4pd26SWDA?view_as=subscriber?sub_confirmation=1">
        <YouTubeIcon />
      </IconButton>
      <IconButton href="https://www.facebook.com/vishwanathprem">
        <FacebookIcon />
      </IconButton>
      <IconButton href="https://vishwanathprem.bandcamp.com/">
        <SvgIcon>
          <BandCamp />
        </SvgIcon>
      </IconButton>
    </Fragment>
  )
}

export default SocialIcons
